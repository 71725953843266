export const useAcl = () => {
    const { permissions } = useAuth()
    const can = (permission) => {
        if (permission === 'access') {
            return true
        }

        if (!permissions.value) {
            return false
        }

        if (typeof permission === 'string') {
            return permissions.value.includes(permission)
        }

        if (Array.isArray(permission)) {
            return permission.every((p) => permissions.value.includes(p))
        }

        return false
    }

    return {
        can,
    }
}
